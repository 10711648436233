import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
//Import an entire module for side effects only, without importing anything.
//This runs the module's global code, but doesn't actually import any values.
//It set global variable for Plotly before loading plotly.js
import 'utils';

import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder.addRadio({
    path: 'graphType',
    defaultValue: 'heatmap',
    name: 'Graph type',
    settings: {
      options: [
        {
          value: 'heatmap',
          label: 'Heat map',
        },
        {
          value: 'dir_perc',
          label: 'Direction percentage',
        },
      ],
    },
  });
});
