export default class DirectionPercentage {
  config() {
    return {
      displayModeBar: false,
    };
  }

  data() {
    return [
      {
        line: {
          color: 'red',
          width: 2,
        },
        mode: 'markers',
        type: 'scatter',
      },
    ];
  }

  layout() {
    return {
      font: {
        color: 'darkgrey',
      },
      paper_bgcolor: '#0000',
      plot_bgcolor: '#0000',
      margin: {
        t: 30,
        b: 20,
      },
      xaxis: {
        type: 'date',
      },
    };
  }

  frames() {
    return [];
  }

  script() {
    return `
let fdir = data.series[0].fields[1].values
if (fdir.hasOwnProperty('buffer')){ // for Grafana 7.x compatibility
    fdir = fdir.buffer
}

let fdirnames = ["N","NNE","NE","ENE","E","ESE","SE","SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]

let ndirs = 16
let bins = []
for (var i = 0; i < ndirs; i++) {
 bins.push(22.5*i);
}
let bin_sizes = []
for (var i=0; i < ndirs; i++) {
bin_sizes.push(22.5);
}

console.log("fdirnames")
console.log(fdirnames)

console.log("bins")
console.log(bins)

console.log("fdir")
console.log(fdir)

// construct histogram
let fdir_bins = []
for (var i=0; i< ndirs; i++ ){
fdir_bins.push(0)
}
for (var i=0; i<fdir.length; i++){
var idx_old = (parseInt((fdir[i]/(360.0/ndirs))+0.5)) % ndirs;
var idx = parseInt((fdir[i]+11.25)/22.5);
console.log("Dir = " + fdir[i] + "; Old index = " + idx_old + "; new idx = " + idx)
if ( ( idx >= 0 ) && ( idx < ndirs ) ) {
  fdir_bins[idx]++;
}
}

// calculate percentages
var sum = 0.;
for ( var i = 0; i < ndirs; i++ ) {
sum  = sum + fdir_bins[i];
}
if ( sum > 0 ) {
for ( var i = 0; i < ndirs; i++ ) {
  fdir_bins[i] = 100.*fdir_bins[i] / sum;
}
}

let serie = {
r: fdir_bins,
theta: fdirnames,
type: 'barpolar'
};

return {
data: [serie],
layout:{
  title:'',
  polar: {
    angularaxis: {
      rotation: 90.,
      direction: 'clockwise',
    }
  },
  showlegend: false
}
};`;
  }
  onclick() {
    return `console.log(data);
window.updateVariables({query:{'var-project':'test'}, partial: true});`;
  }
}

export const directionPercentage = new DirectionPercentage();
