export class HeatMap {
  config() {
    return {
      displayModeBar: false,
    };
  }

  data() {
    return [
      {
        line: {
          color: 'red',
          width: 2,
        },
        mode: 'markers',
        type: 'scatter',
      },
    ];
  }

  layout() {
    return {
      font: {
        color: 'darkgrey',
      },
      paper_bgcolor: '#0000',
      plot_bgcolor: '#0000',
      margin: {
        t: 30,
        b: 20,
      },
      xaxis: {
        type: 'date',
      },
    };
  }

  frames() {
    return [];
  }

  script() {
    return `
let fdir = data.series[0].fields[1].values
if (fdir.hasOwnProperty('buffer')){ // for Grafana 7.x compatibility
    fdir = fdir.buffer
}
let fmag = data.series[1].fields[1].values
if (fmag.hasOwnProperty('buffer')){ // for Grafana 7.x compatibility
    fmag = fmag.buffer
}

console.log("directions")
console.log(fdir);
console.log("magnitudes")
console.log(fmag);

let fdirnames = ["N","NNE","NE","ENE","E","ESE","SE","SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]

let serie = {
  r: fmag,
  theta: fdir,
  type: 'scatterpolar',
  marker: {
    color: '#0010ff', 
    size: 20, 
    opacity: 0.2
  }
};

return {
  data: [serie],
  layout:{
    title:'',
    polar: {
      angularaxis: {
        rotation: 90.,
        direction: 'clockwise',
      }
    },
    showlegend: false
  }
};`;
  }

  onclick() {
    return `console.log(data)
window.updateVariables({query:{'var-project':'test'}, partial: true})`;
  }
}

export const heatMap = new HeatMap();
